<template>
  <el-dialog :close-on-click-modal="false"
    v-model="dialogFormVisible"
    :title="name"
    width="90%"
    destroy-on-close
    >
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 资产折旧、摊销及纳税调整明细表 -->
    <div class="big_box4">
     <el-scrollbar :height="contentStyleObj">
      <div class="top_title3">
        <h4>资产折旧、摊销及纳税调整明细表</h4>
        <!-- <div class="bottom_font clearfix">
         <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center"><span></span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div> -->
      </div>
      <table class="content" cellspacing="0" width="100%" style="margin-top:10px">
        <tr class="top_bg">
         <td class="center" style="width:4%;" rowspan= '3'>行次</td>
         <td class="center" style="width:31%;" rowspan= '3' colspan='2'>项目</td>
         <td class="center" colspan='3' style="width:21%;">账载金额</td>
         <td class="center" colspan='5' style="width:35%;">税收金额</td>
         <td class="center" rowspan='2' style="width:7%;">纳税调整金额</td>
        </tr>
        <tr class="top_bg">
          <td class="center" style="width:7%;">资产原值</td>
          <td class="center" style="width:7%;">本年折旧、摊销额</td>
          <td class="center" style="width:7%;">累计折旧、摊销额</td>
          <td class="center" style="width:7%;">资产计税基础</td>
          <td class="center" style="width:7%;">税收折旧、摊销额</td>
          <td class="center" style="width:7%;line-height:20px">享受加速折旧政策的资产按税收一般规定计算的折旧、摊销额</td>
          <td class="center" style="width:7%;">加速折旧、摊销统计额</td>
          <td class="center" style="width:7%;">累计折旧、摊销额</td>
        </tr>
        <tr class="top_bg">
          <td class="center">1</td>
          <td class="center">2</td>
          <td class="center">3</td>
          <td class="center">4</td>
          <td class="center">5</td>
          <td class="center">6</td>
          <td class="center">7=5-6</td>
          <td class="center">8</td>
          <td class="center">9(2-5)</td>
        </tr>
      </table>
      <div class="fixed_box">
        <table class="content" cellspacing="0" style="border-top: none;">
          
          <tr>
            <td class="center" style="width:4%;">1</td>
            <td colspan='3' style="width:31%;">一、固定资产（2+3+4+5+6+7）</td>
            <td class="blue_box" style="width:7%;"><input type="text" v-model="info[0].a1"></td>
            <td class="blue_box" style="width:7%;"><input type="text" v-model="info[0].a2"></td>
            <td class="blue_box" style="width:7%;"><input type="text" v-model="info[0].a3"></td>
            <td class="blue_box" style="width:7%;"><input type="text" v-model="info[0].a4"></td>
            <td class="blue_box" style="width:7%;"><input type="text" v-model="info[0].a5"></td>
            <td class="center" style="width:7%;">{{info[0].a6}}</td>
            <td class="center" style="width:7%;">{{info[0].a7}}</td>
            <td class="blue_box" style="width:7%;"><input type="text" v-model="info[0].a8"></td>
            <td class="blue_box" style="width:7%;"><input type="text" v-model="info[0].a9"></td>
          </tr>
          <tr>
            <td class="center">2</td>
            <td class="center" rowspan= '6' style="width:8%">所有固定资产</td>
            <td colspan='2' class="left_k">（一）房屋、建筑物</td>
            <td class="blue_box"><input type="text" v-model="info[1].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[1].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[1].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[1].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[1].a5"></td>
            <td class="center">{{info[1].a6}}</td>
            <td class="center">{{info[1].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[1].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[1].a9"></td>
          </tr>

          <tr>
            <td class="center">3</td>
            <td colspan='2' class="left_k">（二）飞机、火车、轮船、机器、机械和其他生产设备</td>
            <td class="blue_box"><input type="text" v-model="info[2].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[2].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[2].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[2].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[2].a5"></td>
            <td class="center">{{info[2].a6}}</td>
            <td class="center">{{info[2].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[2].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[2].a9"></td>
          </tr>

          <tr>
            <td class="center">4</td>
            <td colspan='2' class="left_k">（三）与生产经营活动有关的器具、工具、家具等</td>
            <td class="blue_box"><input type="text" v-model="info[3].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[3].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[3].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[3].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[3].a5"></td>
            <td class="center">{{info[3].a6}}</td>
            <td class="center">{{info[3].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[3].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[3].a9"></td>
          </tr>
          <tr>
            <td class="center">5</td>
            <td colspan='2' class="left_k">（四）飞机、火车、轮船以外的运输工具</td>
            <td class="blue_box"><input type="text" v-model="info[4].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[4].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[4].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[4].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[4].a5"></td>
            <td class="center">{{info[4].a6}}</td>
            <td class="center">{{info[4].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[4].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[4].a9"></td>
          </tr>
          <tr>
            <td class="center">6</td>
            <td colspan='2' class="left_k">（五）电子设备</td>
            <td class="blue_box"><input type="text" v-model="info[5].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[5].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[5].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[5].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[5].a5"></td>
            <td class="center">{{info[5].a6}}</td>
            <td class="center">{{info[5].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[5].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[5].a9"></td>
          </tr>
          <tr>
            <td class="center">7</td>
            <td colspan='2' class="left_k">（六）其他</td>
            <td class="blue_box"><input type="text" v-model="info[6].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[6].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[6].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[6].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[6].a5"></td>
            <td class="center">{{info[6].a6}}</td>
            <td class="center">{{info[6].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[6].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[6].a9"></td>
          </tr>

          <tr>
            <td class="center">8</td>
            <td class="center" rowspan= '20' style="width:7%">其中：享受固定资产加速折旧及一次性扣除政策的资产加速折旧额大于一般折旧额的部分</td>
            <td colspan='2'>（一）重要行业固定资产加速折旧（不含一次性扣除）</td>
            <td class="blue_box"><input type="text" v-model="info[7].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[7].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[7].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[7].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[7].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[7].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[7].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[7].a8"></td>
            <td class="center">{{info[7].a9}}</td>
          </tr>
          <tr>
            <td class="center">9</td>
            <td colspan='2'>（二）其他行业研发设备加速折旧</td>
            <td class="blue_box"><input type="text" v-model="info[8].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[8].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[8].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[8].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[8].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[8].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[8].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[8].a8"></td>
            <td class="center">{{info[8].a9}}</td>
          </tr>
          <tr>
            <td class="center">10</td>
            <td colspan='2'>（三）特定地区企业国定资产加速折旧</td>
            <td class="blue_box"><input type="text" v-model="info[9].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[9].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[9].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[9].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[9].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[9].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[9].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[9].a8"></td>
            <td class="center">{{info[9].a9}}</td>
          </tr>
          <tr>
            <td class="center">11</td>
            <td colspan='2'>1.海南自由贸易港企业固定资产加速折旧</td>
            <td class="blue_box"><input type="text" v-model="info[10].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[10].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[10].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[10].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[10].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[10].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[10].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[10].a8"></td>
            <td class="center">{{info[10].a9}}</td>
          </tr>
          <tr>
            <td class="center">12</td>
            <td colspan='2'>2.横琴粤澳深度合作区企业固定资产加速折旧</td>
            <td class="blue_box"><input type="text" v-model="info[11].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[11].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[11].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[11].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[11].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[11].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[11].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[11].a8"></td>
            <td class="center">{{info[11].a9}}</td>
          </tr>
          <tr>
            <td class="center">13</td>
            <td colspan='2'>（四）500万元以下设备器具一次性扣除</td>
            <td class="blue_box"><input type="text" v-model="info[12].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[12].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[12].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[12].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[12].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[12].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[12].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[12].a8"></td>
            <td class="center">{{info[12].a9}}</td>
          </tr>
          <tr>
            <td class="center">14</td>
            <td colspan='2'>1.高新技术企业2022年第四季度(10月-12月)购置单价500万元以下设备器具一次性扣除</td>
            <td class="blue_box"><input type="text" v-model="info[13].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[13].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[13].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[13].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[13].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[13].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[13].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[13].a8"></td>
            <td class="center">{{info[13].a9}}</td>
          </tr>
          <tr>
            <td class="center">15</td>
            <td colspan='2'>2.购置单价500万元以下设备器具一次性扣除(不包含高新技术企业2022年第四季度购置)</td>
            <td class="blue_box"><input type="text" v-model="info[14].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[14].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[14].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[14].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[14].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[14].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[14].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[14].a8"></td>
            <td class="center">{{info[14].a9}}</td>
          </tr>
          <tr>
            <td class="center">16</td>
            <td colspan='2'>（五）500万元以上设备器具一次性扣除</td>
            <td class="blue_box"><input type="text" v-model="info[15].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[15].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[15].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[15].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[15].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[15].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[15].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[15].a8"></td>
            <td class="center">{{info[15].a9}}</td>
          </tr>
          <tr>
            <td class="center">17</td>
            <td class="center" rowspan= '3' style="width:7%">中小微企业购置单价500万元以上设备器具</td>
            <td>1.最低折旧年限为3年的设备器具一次性扣除</td>
            <td class="blue_box"><input type="text" v-model="info[16].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[16].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[16].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[16].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[16].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[16].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[16].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[16].a8"></td>
            <td class="center">{{info[16].a9}}</td>

          </tr>
          <tr>
            <td class="center">18</td>
            <td>2.最低折1日年限为4、5年的设备器具50%部分一次性扣除</td>
            <td class="blue_box"><input type="text" v-model="info[17].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[17].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[17].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[17].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[17].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[17].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[17].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[17].a8"></td>
            <td class="center">{{info[17].a9}}</td>
          </tr>
          <tr>
            <td class="center">19</td>
            <td>3.最低折旧年限为10年的设备器具50%部分一次性扣除</td>
            <td class="blue_box"><input type="text" v-model="info[18].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[18].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[18].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[18].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[18].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[18].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[18].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[18].a8"></td>
            <td class="center">{{info[18].a9}}</td>
          </tr>
          <tr>
            <td class="center">20</td>
            <td colspan='2'>4.高新技术企业2022年第四季度(10月-12月)购置单价500万元以上设备器具一次性扣除</td>
            <td class="blue_box"><input type="text" v-model="info[19].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[19].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[19].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[19].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[19].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[19].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[19].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[19].a8"></td>
            <td class="center">{{info[19].a9}}</td>
          </tr>
          <tr>
            <td class="center">21</td>
            <td colspan='2'>（六）特定地区企业国定资产一次性扣除</td>
            <td class="blue_box"><input type="text" v-model="info[20].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[20].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[20].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[20].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[20].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[20].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[20].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[20].a8"></td>
            <td class="center">{{info[20].a9}}</td>
          </tr>
          
          <tr>
            <td class="center">22</td>
            <td colspan='2'>1.海南自由贸易港企业固定资产一次性扣除</td>
            <td class="blue_box"><input type="text" v-model="info[21].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[21].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[21].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[21].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[21].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[21].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[21].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[21].a8"></td>
            <td class="center">{{info[21].a9}}</td>
          </tr>
          <tr>
            <td class="center">23</td>
            <td colspan='2'>2.横琴粤澳深度合作区企业固定资产一次性扣除</td>
            <td class="blue_box"><input type="text" v-model="info[22].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[22].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[22].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[22].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[22].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[22].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[22].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[22].a8"></td>
            <td class="center">{{info[22].a9}}</td>
          </tr>
          <tr>
            <td class="center">24</td>
            <td colspan='2'>（七）技术进步、更新换代固定资产加速折旧</td>
            <td class="blue_box"><input type="text" v-model="info[23].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[23].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[23].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[23].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[23].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[23].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[23].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[23].a8"></td>
            <td class="center">{{info[23].a9}}</td>
          </tr>
          <tr>
            <td class="center">25</td>
            <td colspan='2'>（八）常年强震动、高腐蚀固定资产加速折旧</td>
            <td class="blue_box"><input type="text" v-model="info[24].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[24].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[24].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[24].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[24].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[24].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[24].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[24].a8"></td>
            <td class="center">{{info[24].a9}}</td>
          </tr>
          <tr>
            <td class="center">26</td>
            <td colspan='2'>（九）外购软件加速折旧</td>
            <td class="blue_box"><input type="text" v-model="info[25].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[25].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[25].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[25].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[25].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[25].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[25].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[25].a8"></td>
            <td class="center">{{info[25].a9}}</td>
          </tr>
          <tr>
            <td class="center">27</td>
            <td colspan='2'>（十）集成电路企业生产设备加速折旧</td>
            <td class="blue_box"><input type="text" v-model="info[26].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[26].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[26].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[26].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[26].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[26].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[26].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[26].a8"></td>
            <td class="center">{{info[26].a9}}</td>
          </tr>

          <tr>
            <td class="center">28</td>
            <td colspan='3'>二、生产性生物资产（29+30）</td>
            <td class="blue_box"><input type="text" v-model="info[27].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[27].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[27].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[27].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[27].a5"></td>
            <td class="center">{{info[27].a6}}</td>
            <td class="center">{{info[27].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[27].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[27].a9"></td>
          </tr>
          <tr>
            <td class="center">29</td>
            <td class="left_k" colspan='3'>（一）林木类</td>
            <td class="blue_box"><input type="text" v-model="info[28].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[28].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[28].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[28].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[28].a5"></td>
            <td class="center">{{info[28].a6}}</td>
            <td class="center">{{info[28].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[28].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[28].a9"></td>
          </tr>
          <tr>
            <td class="center">30</td>
            <td class="left_k" colspan='3'>（二）畜类</td>
            <td class="blue_box"><input type="text" v-model="info[29].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[29].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[29].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[29].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[29].a5"></td>
            <td class="center">{{info[29].a6}}</td>
            <td class="center">{{info[29].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[29].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[29].a9"></td>
          </tr>

          <tr>
            <td class="center">31</td>
            <td colspan='3'>三、无形资产（32+33+34+35+36+37+38+39）</td>
            <td class="blue_box"><input type="text" v-model="info[30].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[30].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[30].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[30].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[30].a5"></td>
            <td class="center">{{info[30].a6}}</td>
            <td class="center">{{info[30].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[30].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[30].a9"></td>
          </tr>
          <tr>
            <td class="center">32</td>
            <td class="center" rowspan= '8' style="width:7%">所有无形资产</td>
            <td colspan='2' class="left_k">（一）专利权</td>
            <td class="blue_box"><input type="text" v-model="info[31].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[31].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[31].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[31].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[31].a5"></td>
            <td class="center">{{info[31].a6}}</td>
            <td class="center">{{info[31].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[31].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[31].a9"></td>
          </tr>
          <tr>
            <td class="center">33</td>
            <td colspan='2' class="left_k">（二）商标权</td>
            <td class="blue_box"><input type="text" v-model="info[32].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[32].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[32].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[32].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[32].a5"></td>
            <td class="center">{{info[32].a6}}</td>
            <td class="center">{{info[32].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[32].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[32].a9"></td>
          </tr>
          <tr>
            <td class="center">34</td>
            <td colspan='2' class="left_k">（三）著作权</td>
            <td class="blue_box"><input type="text" v-model="info[33].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[33].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[33].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[33].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[33].a5"></td>
            <td class="center">{{info[33].a6}}</td>
            <td class="center">{{info[33].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[33].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[33].a9"></td>
          </tr>
          <tr>
            <td class="center">35</td>
            <td colspan='2' class="left_k">（四）土地使用权</td>
            <td class="blue_box"><input type="text" v-model="info[34].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[34].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[34].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[34].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[34].a5"></td>
            <td class="center">{{info[34].a6}}</td>
            <td class="center">{{info[34].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[34].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[34].a9"></td>
          </tr>
          <tr>
            <td class="center">36</td>
            <td colspan='2' class="left_k">（五）非专利技术</td>
            <td class="blue_box"><input type="text" v-model="info[35].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[35].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[35].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[35].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[35].a5"></td>
            <td class="center">{{info[35].a6}}</td>
            <td class="center">{{info[35].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[35].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[35].a9"></td>
          </tr>
          <tr>
            <td class="center">37</td>
            <td colspan='2' class="left_k">（六）特许权使用费</td>
            <td class="blue_box"><input type="text" v-model="info[36].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[36].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[36].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[36].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[36].a5"></td>
            <td class="center">{{info[36].a6}}</td>
            <td class="center">{{info[36].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[36].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[36].a9"></td>
          </tr>
          <tr>
            <td class="center">38</td>
            <td colspan='2' class="left_k">（七）软件</td>
            <td class="blue_box"><input type="text" v-model="info[37].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[37].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[37].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[37].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[37].a5"></td>
            <td class="center">{{info[37].a6}}</td>
            <td class="center">{{info[37].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[37].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[37].a9"></td>
          </tr>
          <tr>
            <td class="center">39</td>
            <td colspan='2' class="left_k">（八）其他</td>
            <td class="blue_box"><input type="text" v-model="info[38].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[38].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[38].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[38].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[38].a5"></td>
            <td class="center">{{info[38].a6}}</td>
            <td class="center">{{info[38].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[38].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[38].a9"></td>
          </tr>
          <tr>
            <td class="center">40</td>
            <td class="center" rowspan= '7' style="width:7%">其中：享受无形资产加速摊销及一次性摊销政策的资产加速摊销额大于一般摊销额的部分</td>
            <td colspan='2' class="left_k">（一）企业外购软件加速摊销</td>
            <td class="blue_box"><input type="text" v-model="info[39].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[39].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[39].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[39].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[39].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[39].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[39].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[39].a8"></td>
            <td class="center">{{info[39].a9}}</td>
          </tr>
          <tr>
            <td class="center">41</td>
            <td colspan='2' class="left_k">（二）特定地区企业无形资产加速摊销</td>
            <td class="blue_box"><input type="text" v-model="info[40].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[40].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[40].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[40].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[40].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[40].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[40].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[40].a8"></td>
            <td class="center">{{info[40].a9}}</td>
          </tr>
          <tr>
            <td class="center">42</td>
            <td colspan='2' class="left_k">1.海南自由贸易港企业无形资产加速摊销</td>
            <td class="blue_box"><input type="text" v-model="info[41].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[41].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[41].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[41].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[41].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[41].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[41].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[41].a8"></td>
            <td class="center">{{info[41].a9}}</td>
          </tr>
          <tr>
            <td class="center">43</td>
            <td colspan='2' class="left_k">2.琴粤澳深度合作企业无形资产加速摊销</td>
            <td class="blue_box"><input type="text" v-model="info[42].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[42].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[42].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[42].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[42].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[42].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[42].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[42].a8"></td>
            <td class="center">{{info[42].a9}}</td>
          </tr>
          <tr>
            <td class="center">44</td>
            <td colspan='2' class="left_k">（三）特定地区企业无形资产一次性摊销</td>
            <td class="blue_box"><input type="text" v-model="info[43].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[43].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[43].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[43].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[43].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[43].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[43].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[43].a8"></td>
            <td class="center">{{info[43].a9}}</td>
          </tr>
          <tr>
            <td class="center">45</td>
            <td colspan='2' class="left_k">1.海南自由贸易港企业无形资产一次性摊销</td>
            <td class="blue_box"><input type="text" v-model="info[44].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[44].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[44].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[44].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[44].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[44].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[44].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[44].a8"></td>
            <td class="center">{{info[44].a9}}</td>
          </tr>
          <tr>
            <td class="center">46</td>
            <td colspan='2' class="left_k">2.横琴粤澳深度合作区企业无形资产一次性摊销</td>
            <td class="blue_box"><input type="text" v-model="info[45].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[45].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[45].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[45].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[45].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[45].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[45].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[45].a8"></td>
            <td class="center">{{info[45].a9}}</td>
          </tr>

          <tr>
            <td class="center">47</td>
            <td colspan='3'>四、长期待摊费用（48+49+50+51+52）</td>
            <td class="blue_box"><input type="text" v-model="info[46].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[46].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[46].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[46].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[46].a5"></td>
            <td class="center">{{info[46].a6}}</td>
            <td class="center">{{info[46].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[46].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[46].a9"></td>
          </tr>
          <tr>
            <td class="center">48</td>
            <td class="left_k" colspan='3'>（一）已足额提取折旧的固定资产的改建支出</td>
            <td class="blue_box"><input type="text" v-model="info[47].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[47].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[47].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[47].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[47].a5"></td>
            <td class="center">{{info[47].a6}}</td>
            <td class="center">{{info[47].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[47].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[47].a9"></td>
          </tr>
          <tr>
            <td class="center">49</td>
            <td class="left_k" colspan='3'>（二）租入固定资产的改建支出</td>
            <td class="blue_box"><input type="text" v-model="info[48].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[48].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[48].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[48].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[48].a5"></td>
            <td class="center">{{info[48].a6}}</td>
            <td class="center">{{info[48].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[48].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[48].a9"></td>
          </tr>
          <tr>
            <td class="center">50</td>
            <td class="left_k" colspan='3'>（三）固定资产的大修理支出</td>
            <td class="blue_box"><input type="text" v-model="info[49].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[49].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[49].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[49].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[49].a5"></td>
            <td class="center">{{info[49].a6}}</td>
            <td class="center">{{info[49].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[49].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[49].a9"></td>
          </tr>
          <tr>
            <td class="center">51</td>
            <td class="left_k" colspan='3'>（四）开办费</td>
            <td class="blue_box"><input type="text" v-model="info[50].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[50].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[50].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[50].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[50].a5"></td>
            <td class="center">{{info[50].a6}}</td>
            <td class="center">{{info[50].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[50].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[50].a9"></td>
          </tr>
          <tr>
            <td class="center">52</td>
            <td class="left_k" colspan='3'>（五）其他</td>
            <td class="blue_box"><input type="text" v-model="info[51].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[51].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[51].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[51].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[51].a5"></td>
            <td class="center">{{info[51].a6}}</td>
            <td class="center">{{info[51].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[51].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[51].a9"></td>
          </tr>

          <tr>
            <td class="center">53</td>
            <td colspan='3'>五、油气勘探投资</td>
            <td class="blue_box"><input type="text" v-model="info[52].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[52].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[52].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[52].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[52].a5"></td>
            <td class="center">{{info[52].a6}}</td>
            <td class="center">{{info[52].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[52].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[52].a9"></td>
          </tr>

          <tr>
            <td class="center">54</td>
            <td colspan='3'>六、油气开发投资</td>
            <td class="blue_box"><input type="text" v-model="info[53].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[53].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[53].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[53].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[53].a5"></td>
            <td class="center">{{info[53].a6}}</td>
            <td class="center">{{info[53].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[53].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[53].a9"></td>
          </tr>

          <tr>
            <td class="center">55</td>
            <td colspan='3'>合计（1+28+31+47+53+54）</td>
            <td class="blue_box"><input type="text" v-model="info[54].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[54].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[54].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[54].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[54].a5"></td>
            <td class="blue_box"><input type="text" v-model="info[54].a6"></td>
            <td class="blue_box"><input type="text" v-model="info[54].a7"></td>
            <td class="blue_box"><input type="text" v-model="info[54].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[54].a9"></td>
          </tr>
          <tr>
            <td  class="center">附列资料</td>
            <td colspan='3'>全民所有制企业公司制改制资产评估增值政策资产</td>
            <td class="blue_box"><input type="text" v-model="info[55].a1"></td>
            <td class="blue_box"><input type="text" v-model="info[55].a2"></td>
            <td class="blue_box"><input type="text" v-model="info[55].a3"></td>
            <td class="blue_box"><input type="text" v-model="info[55].a4"></td>
            <td class="blue_box"><input type="text" v-model="info[55].a5"></td>
            <td class="center">{{info[55].a6}}</td>
            <td class="center">{{info[55].a7}}</td>
            <td class="blue_box"><input type="text" v-model="info[55].a8"></td>
            <td class="blue_box"><input type="text" v-model="info[55].a9"></td>
          </tr>
        </table>
      </div>
      </el-scrollbar>
      <div class="save_box">
        <el-button type="primary" size="small" @click="saveDetail()" :disabled="!$buttonStatus('hsqj_bbbc')"><i class="iconfont icon-baocun"></i>保存</el-button>
      </div>
    </div>
    
    <!-- 资产折旧、摊销及纳税调整明细表 end -->
  </div>
</el-dialog>

</template>
<script>
import { yearInfo, yearEdit } from "@/api/year"
export default {
  name: "A105080",
  props: {
    tableYearName: {
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
    startAccountPeriod:{
      default:'',
      type:String
    },
    name:{
      default:'',
      type:String
    }
  },
  data() {
    return {
      comName: '',
      taxNo: '',
      info:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      fullscreenLoading:false,
      dialogFormVisible:false,
      contentStyleObj:{}

    };
  },
  created() {
    this.comName = this.$store.getters['user/comInfo'].comName
    this.taxNo = this.$store.getters['user/comInfo'].taxNo
  },
  mounted(){
    this.contentStyleObj = this.$getHeight(210)
  },
  methods: {
    getParams() {
      this.dialogFormVisible = true

      // const tableName = this.$route.params.tableName;
      const tableName = 'year_a105080'
      this.tableName = tableName
      if (tableName) {
        this.getDetail({comId:this.comId,period:this.startAccountPeriod,tableName:this.tableYearName});
        this.listLoading = true;
      } else {
        this.listLoading = false;
      }
    },
    getDetail(e) {
      yearInfo(e).then(response => {
        this.info = response.data.data;
        //console.log(this.info)
      });
    },
    // 点击保存
    saveDetail(){
      this.fullscreenLoading = true
      yearEdit({comId:this.comId,year:this.startAccountPeriod,tableName:this.tableName,items:this.info}).then(res => {
        if(res.data.msg == 'success'){
          this.$message({
            message: "保存成功",
            type: "success"
          });
        }
        this.fullscreenLoading = false
        this.getDetail({comId:this.comId,period:this.startAccountPeriod,tableName:this.tableYearName})
      });
    }
    
  }
};
</script>

<style lang="scss" scoped>
.border_none .el-select{
  width: 100% !important;
}
.center{
  text-align: center;
}
.right{
  text-align: right;
}
.gary_box{
  background: #f8f8f8;
  text-align: right;
}
.blue_box{
  background: #EBF5FF ;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 28px;
    padding:0 6px;
    color: #666;
    font-size: 12px;
  }
  input{
    width: 100%;
    line-height: 28px;
    // padding: 0 6px;
    font-size: 12px;
    text-align: right;
  }
}
.left_k{
  text-indent: 2em;
}
.left_indent{
  text-indent: 5em;
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 100%;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly{
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  // border: 0;
  // background: #fff;
  cursor: pointer;
}
</style>



