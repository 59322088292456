<template>
  <div style="position: relative;" v-loading="fetchLoading">
    <div class="top_btns clearfix">
      <div class="fr">
        <!-- <qzf-video vid="4467be537414c3dbb26ad6b2d6fa1be4_4"></qzf-video> -->
        <span class="hint" v-if="sels.length == 0"
          ><i class="iconfont icon-gantanhao1"></i
          >批量取数不重置手动修改的报表</span
        >
        <qzf-button button_code="swbb_qs" @success="generalTax" size="small" type="success"   >
          <el-icon><HelpFilled /></el-icon><span  > 批量取数</span>
        </qzf-button>
        <qzf-button button_code="swbb_sx" jz="false" @success="refreshList" size="small" type="primary"  :loading="btnLoading" >
          <el-icon><Refresh /></el-icon><span  > 更新报表</span>
        </qzf-button>
        <qzf-button button_code="swbb_sb" jz="false" @success="shenbao" size="small" type="primary" :loading="sbLoading">
          <el-icon><Tickets /></el-icon><span  > 申报</span>
        </qzf-button>
        <qzf-button button_code="swbb_sb" jz="false" @success="batchKk" size="small" type="primary" :loading="kkLoading">
          <el-icon><Postcard /></el-icon><span  > 扣款</span>
        </qzf-button>
        <el-button size="small" type="primary" plain @click="copyTax" icon="DocumentCopy">复制税款</el-button>
        <!-- <qzf-button button_code="swbb_jtkmsz" @success="handleSubject" size="small" type="primary"   >
          <el-icon><Setting /></el-icon><span  > 计提科目设置</span>
        </qzf-button> -->
        <!-- <settings v-if="$buttonStatus('swbb_sz')" :type="['code_jjdj','code_jjdj_subject_code','code_sffgs','code_xgmdkfpbjsfjs','code_jjdj_kcs','code_jm_tax','code_vat_bc','code_vat_ckts','code_yhsfu','code_yh_in','code_qysds','code_gxqy','code_sds_ms','code_fgs','code_ghjf','code_ghjf_gz','code_ghjf_0','code_yh0','code_yhsjsjshj','code_sl_0' ]"></settings> -->
      </div>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick" class="top_nav" type="border-card">
     
      <!-- 必报列表 -->
      <el-tab-pane label="必报列表" name="first">
        <el-table stripe  :data="list" style="width: 100%;" v-loading="loading" border :height="contentStyleObj" @selection-change="handleSelectionChange">
          <template #empty>
            <el-empty :image-size="150" description="没有数据"></el-empty>
          </template>
          <el-table-column prop="name" type="selection" width="55" align="center"></el-table-column>
          <el-table-column prop="name" label="申报列表名称（财务报表需单独取数）" min-width="250">
            <template #default="scope">
              <span @click="editTax(scope.row)" style="cursor: pointer;">{{$tableNameFilter(scope.row.tableName)}}</span>
              <!-- <span style="margin-left:10px;color:var(--themeColor,#17a2b8);cursor:pointer" @click="declareDetail(scope.row)">申报详情</span>
              <span style="margin-left:10px;color:var(--themeColor,#17a2b8);cursor:pointer" @click="deductionDetail(scope.row)">扣款详情</span> -->
            </template>
          </el-table-column>
          <el-table-column prop="name" label="取数状态" align="left" min-width="135">
            <template #default="scope">
              <div class="item_icon" v-if="scope.row.status == 1">
                <i class="iconfont icon-duihao"></i>
                <p>已取数<span v-if="scope.row.byHand == 1" style="color: #17a2b8;margin-left: 3px">(手动调整)</span>
                </p>
              </div>
              <div class="item_icon" v-else-if="scope.row.status == 3">
                <i class="iconfont icon-gantanhao yczt"></i>
                <p>需重新取数
                </p>
              </div>
              <div class="item_icon" v-else>
                <i class="iconfont icon-gantanhao"></i>
                <p>未取数</p>
              </div>
            </template>
          </el-table-column>
           <el-table-column label="取数时间" min-width="145" align="center">
            <template #default="scope">
              <span v-if="scope.row.status == 1">{{$parseTime(scope.row.updatedAt,'{y}-{m}-{d} {h}:{i}:{s}')}}</span>
              <span v-else></span>
            </template>
          </el-table-column>
          <el-table-column label="税款确认状态" width="100" v-if="orgId == 3576 && automatic != 1">
          <template #default="scope">
            <div class="item_icon">
              <i :class="$homeConfirmIcon(scope.row.djqrTaxStatus)"></i>
              <span>{{ $homeTaxStatus(scope.row.djqrTaxStatus) }}</span>
            </div>
          </template>
        </el-table-column>
          <el-table-column prop="taskStatus" label="申报状态" align="left" min-width="180">
            <template #default="scope">
              <div class="item_icon" v-if="scope.row.tableName == 'gs_gs' || scope.row.tableName == 'gs_gs_one' || scope.row.tableName == 'gs_gs_hd'">
                <el-tooltip placement="top"  :disabled="!scope.row.errLog && !scope.row.businessLog" effect="dark">
                  <template #content>
                    <div v-html="(scope.row.errLog? scope.row.errLog:'') + (scope.row.businessLog?scope.row.businessLog:'')"></div>
                  </template>
                  <p>
                    <span v-if="scope.row.image" class="demo-image__preview" >
                      <i class="iconfont icon-info" v-if="scope.row.taskStatus == 1"></i>
                      <i class="iconfont icon-picture" style="color:red;font-size:16px;" v-else-if="scope.row.businessStatus == 3 ||scope.row.businessStatus == 4 ||scope.row.businessStatus == 5 || scope.row.businessStatus == 6|| scope.row.businessStatus == 12" @click="open(scope.row, scope.$index)"></i>
                      <i class="iconfont icon-picture" style="color:#e6a23c;font-size:16px;" v-else-if=" scope.row.businessStatus == 8 " @click="open(scope.row, scope.$index)"></i>
                      <i class="iconfont icon-picture" style="color:#67c23a;font-size:16px;" v-else-if="scope.row.businessStatus == 10 ||scope.row.businessStatus == 14 ||scope.row.businessStatus == 13 || scope.row.businessStatus == 20 " @click="open(scope.row, scope.$index)"></i>
                      <i class="iconfont icon-picture" style="color:#39b0d2;font-size:16px;" v-else-if="scope.row.businessStatus == 11 || scope.row.businessStatus == 2 || scope.row.businessStatus == 7" @click="open(scope.row, scope.$index)"></i>
                    </span>
                    <i v-else :class="$setUpStatusGsIconSb(scope.row.taskStatus,scope.row.businessStatus)"></i>
                    <p :class="$setUpStatusGsWordSb(scope.row.taskStatus, scope.row.businessStatus)" style="border: 0;">{{$setUpStatusGsSb(scope.row.taskStatus,scope.row.businessStatus)}}</p>
                  <i class="iconfont icon-wenhao" v-if="((scope.row.businessStatus == 3 ||scope.row.businessStatus == 4 ||scope.row.businessStatus == 5 || scope.row.businessStatus == 6|| scope.row.businessStatus == 12) && scope.row.businessLog)|| scope.row.taskStatus == '4'" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                  </p>
                </el-tooltip> 
              </div>
              <el-tooltip placement="top"  v-else :disabled="!scope.row.errLog && !scope.row.businessLog" effect="dark">
                <template #content>
                  <div v-html="scope.row.errLog? scope.row.errLog:'' + scope.row.businessLog?scope.row.businessLog:''"></div>
                </template>
                <p class="item_icon">
                  <span v-if="scope.row.image" class="demo-image__preview" >
                    <i class="iconfont icon-info" v-if="scope.row.taskStatus == 1"></i>
                    <i class="iconfont icon-picture" style="color:red;font-size:16px;" v-else-if="scope.row.businessStatus == 3" @click="open(scope.row, scope.$index)"></i>
                    <i class="iconfont icon-picture" style="color:#67c23a;font-size:16px;" v-else-if="scope.row.businessStatus == 2 ||scope.row.businessStatus == 6 ||scope.row.businessStatus == 20  || scope.row.businessStatus == 22" @click="open(scope.row, scope.$index)"></i>
                    <i class="iconfont icon-picture" style="color:#39b0d2;font-size:16px;" v-else-if="scope.row.businessStatus == 4 || scope.row.businessStatus == 5 || scope.row.businessStatus == 40 || scope.row.businessStatus == 60 || scope.row.businessStatus == 21" @click="open(scope.row, scope.$index)"></i>
                    <i class="iconfont icon-picture" style="color:#e6a23c;font-size:16px;" v-else-if="scope.row.businessStatus == 23" @click="open(scope.row, scope.$index)"></i>

                  </span>
                  <i v-if="!scope.row.image" :class="$batchSendIconSb(scope.row.taskStatus, scope.row.businessStatus)"></i>
                  <p size="small" :class="$batchSendWordSb(scope.row.taskStatus, scope.row.businessStatus)" style="border: 0;">{{$batchSendSb(scope.row.taskStatus,scope.row.businessStatus)}}</p>
                  <i class="iconfont icon-wenhao" v-if="(scope.row.businessStatus == 3 && scope.row.businessLog) || scope.row.taskStatus == 4" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                </p>
              </el-tooltip>

            </template>
          </el-table-column>
          <el-table-column prop="name" label="申报详情" min-width="85" align="center">
            <template #default="scope">
              <el-button type="text" style="cursor:pointer" @click="declareDetail(scope.row)" :disabled="scope.row.taskStatus == 0 || scope.row.taskStatus == ''">查看记录</el-button>
            </template>
          </el-table-column>


          <el-table-column prop="name" label="扣款状态" align="left" min-width="110">
            <template #default="scope">
              <div class="item_icon" v-if="scope.row.tableName == 'gs_gs' || scope.row.tableName == 'gs_gs_one' || scope.row.tableName == 'gs_gs_hd'">
                <p>
                  <span v-if="scope.row.image2" class="demo-image__preview" >
                    <i class="iconfont icon-picture" style="color:#409eff;font-size:16px;cursor: pointer;" @click="open2(scope.row, scope.$index)"></i>
                  </span>
                  <i v-if="!scope.row.image2"  :class="$setUpStatusGsIconSb(scope.row.taskStatus2,scope.row.businessStatus2)"></i>
                  <el-tooltip v-if="scope.row.businessLog2" class="item" effect="dark" placement="top-start">
                    <template #content>
                      <div v-html="scope.row.businessLog2?scope.row.businessLog2:'' + scope.row.errLog2?scope.row.errLog2:''"></div>
                    </template>
                    <span>{{$setUpStatusGsKk(scope.row.taskStatus2, scope.row.businessStatus2)}}</span>
                  </el-tooltip>
                  <span v-else>
                    <span>{{$setUpStatusGsKk(scope.row.taskStatus2, scope.row.businessStatus2)}}</span>
                  </span>
                </p>
                <el-tooltip :disabled="(scope.row.errLog2?scope.row.errLog2:'' + scope.row.businessLog2?scope.row.businessLog2:'') == ''?true:false" class="item" effect="dark" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.errLog2?scope.row.errLog2:'' + scope.row.businessLog2?scope.row.businessLog2:''"></div>
                  </template>
                  <div style="display:inline-block">
                    <i class="iconfont icon-wenhao" v-if="(scope.row.errLog2 && scope.row.errLog2 != '成功')|| (scope.row.businessLog2 && scope.row.businessLog2 !='成功')" style="font-size:15px;color: red;margin-left:4px"></i>
                  </div>
                </el-tooltip>
              </div>
              <el-tooltip placement="top" :disabled="!scope.row.errLog2 && !scope.row.businessLog2" effect="dark" v-else>
                <template #content>
                  <div v-html="scope.row.errLog2?scope.row.errLog2:'' + scope.row.businessLog2?scope.row.businessLog2:''"></div>
                </template>
                <p class="item_icon">
                  <span v-if="scope.row.image2">
                    <i class="iconfont icon-info" v-if="scope.row.taskStatus2 == 1"></i>
                    <i class="iconfont icon-picture" style="color:red;font-size:16px;" v-else-if="scope.row.businessStatus2 == 3" @click="open2(scope.row, scope.$index)"></i>
                    <i class="iconfont icon-picture" style="color:#67c23a;font-size:16px;" v-else-if="scope.row.businessStatus2 == 2 ||scope.row.businessStatus2 == 13 ||scope.row.businessStatus2 == 22" @click="open2(scope.row, scope.$index)"></i>
                  </span>
                  <i :class="$batchSendIconSb(scope.row.taskStatus2, scope.row.businessStatus2)" v-else></i>
                  <span>{{$batchSendKk(scope.row.taskStatus2,scope.row.businessStatus2)}}</span>
                </p>
              </el-tooltip>
            </template>
          </el-table-column>


          <el-table-column prop="name" label="扣款详情" min-width="85" align="center">
            <template #default="scope">
              <el-button type="text" style="cursor:pointer" @click="deductionDetail(scope.row)" :disabled="scope.row.taskStatus2 == 0 || scope.row.taskStatus2 == ''">查看记录</el-button>
            </template>
          </el-table-column>
         
          <el-table-column prop="name" label="税款" align="center" min-width="120">
            <template #default="scope">
              <span v-if="scope.row.tax && scope.row.tax != '0'">{{scope.row.tax}}
                <span v-if="scope.row.tax_fjs && scope.row.tax_fjs != '0'"> + {{scope.row.tax_fjs}}</span>
                <span v-if="scope.row.taxOnly && scope.row.taxOnly != '0'"> + {{scope.row.taxOnly}}</span>
              </span>
              <span v-else>
                {{0}}
                <span v-if="scope.row.tax_fjs && scope.row.tax_fjs != '0'"> + {{scope.row.tax_fjs}}</span>
                <span v-if="scope.row.taxOnly && scope.row.taxOnly != '0'"> + {{scope.row.taxOnly}}</span>
              </span>
              <el-tooltip class="item" effect="dark" placement="top-start" :content="'与扣缴端税额('+scope.row.amountPaid+')不一致'">
                <i class="iconfont icon-wenhao" v-if="(scope.row.taskName == 'gs-sb-gs'||scope.row.taskName == 'gs-sb-gd') && scope.row.taskStatus == 3 && scope.row.businessStatus != 14&& ((this.eval1(scope.row.tax,scope.row.taxOnly,scope.row.tax_fjs) != scope.row.amountPaid*1 && this.eval1(scope.row.tax,scope.row.taxOnly,scope.row.tax_fjs) >=1) ||(this.eval1(scope.row.tax,scope.row.taxOnly,scope.row.tax_fjs) != scope.row.amountPaid*1 && this.eval1(scope.row.tax,scope.row.taxOnly,scope.row.tax_fjs) == 0) )" style="font-size:15px;color: red;margin-left:4px"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="税负率" align="center" min-width="80">
            <template #default="scope">
              <span  v-if="scope.row.sf">{{scope.row.sf}}</span>
              <span v-else>{{0}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="年税负率" align="center" min-width="80">
            <template #default="scope">
              <span v-if="scope.row.sfYear">{{scope.row.sfYear}}</span>
              <span v-else>{{0}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" class-name="top_right_btns" min-width="130">
            <template #default="scope">
              <div class="item_icon" @click="editTax(scope.row)" v-if="scope.row.tableName != 'gs_gs_one' && scope.row.tableName != 'gs_gs_hd'">
                <el-button type="text" :disabled="scope.row.status !=1 && scope.row.status !=3">查看报表</el-button>
              </div>
              <div class="item_icon" v-if="scope.row.tableName != 'gs_gs_one' && scope.row.tableName != 'gs_gs' && scope.row.tableName != 'gs_cwbb' && scope.row.tableName != 'gs_tax_dqde' && scope.row.tableName != 'gs_gs_hd'">
                <el-button type="text"  @click="fetchNumber(scope.row)" v-if="$buttonStatus('swbb_qs')">取数</el-button>
              </div>
              <!-- 财务报表取数 -->
              <div class="item_icon" v-if="scope.row.tableName == 'gs_cwbb'">
                <el-button type="text" @click="cwbbFetchNumber(scope.row)" v-if="$buttonStatus('kjbb_scbb')">取数</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <!-- 必报列表 end -->
    </el-tabs>
  </div>
  <provision ref="provision"></provision>
  <declareDetails ref="declareDetails"></declareDetails>
  <settingDialog ref="settingDialog"></settingDialog>
  <salaryDialog ref="salaryDialog"/>
  <sbImage ref="sbImage" />
</template>

<script>
import { taxList , taxCreate ,createKj , updateSbList} from "@/api/taxCalculation"
import provision from './vat-invoice/component/provision.vue'
import declareDetails from "../../batch/components/declareDetails.vue"
import { sendTask } from "@/api/collection"
import salaryDialog from "./components/salaryDialog.vue"
import sbImage from '@/components/sbImage'
export default {
  name:'tax',
  components: { 
    provision,
    declareDetails,
    salaryDialog,sbImage },
  data() {
    return {
      sfCheck: false,//判断是否选中列表数据 用来判断点击批量取数是否弹窗 若选中列表数据 弹窗 不选 不弹窗
      list:[],
      activeName:'first',
      loading:false,
      fetchLoading:false,
      period:this.$store.getters['user/comInfo'].period,
      gsMainIds:undefined,
      tableNames:[],
      btnLoading:false,
      settingMsg:'',
      comId:this.$store.getters['user/comInfo'].comId * 1,
      sels:[],
      sbLoading:false,
      comName:this.$store.getters['user/comInfo'].comName,
      automatic:this.$store.getters['user/comInfo'].automatic,
      kkLoading:false,
      orgId: this.$store.getters["user/user"].orgId,
      disCode:this.$store.getters['user/comInfo'].disCode,
      addressList:['13','32','14','11','44','36','42','50','23','64','65']
    }
  },
  deactivated(){
    if(!this.$getCachedViews("tax")){//销毁 mitt bus
      this.$bus.off("taxUpdate")
    }
  },
  created(){
    this.getList()
    this.initBus()
    this.contentStyleObj= this.$getHeight(165)
  },
  methods:{
    initBus(){
      this.$bus.on("taxUpdate", (val) => {
        this.getList()
      });
    },
    editTax(row){
      if(row.status != 1 && row.status != 3){
        this.$qzfMessage("请先取数", 1)
        return
      }
      // this.$router.push({
      //   // path: "/report/smallVat",  //小规模
      //   // path: "/report/qysds",     //企业所得税
      //   path: "/report/vatInvoice",   //一般纳税人
      // });
      if(row.tableName == 'kj_lrb'|| row.tableName == 'kj_zcfz'|| row.tableName == 'gs_cwbb'){
          this.$router.push({
          path: "/report/finance",   //会计报表
        });
      }else if(row.tableName == 'gs_vat'){
          this.$router.push({
          path: "/report/vatInvoice",   //增值税报表 一般纳税人
        });
      }else if(row.tableName == 'gs_small_vat'){
          this.$router.push({
          path: "/report/smallVat",   //增值税报表 小规模
        });
      }else if(row.tableName == 'gs_tax_quarter'){
          this.$router.push({
          path: "/report/qysds",   //企业所得税
        });
      }else if(row.tableName == 'gs_deed'){
          this.$router.push({
          path: "/report/propertyTax",   //财产和行为纳税申报表
        });
      }else if(row.tableName == 'gs_qtsr'){
          this.$router.push({
          path: "/report/taxNeimengQtsr",   //其他收入
        });
      }else if(row.tableName == 'gs_sl'){
          this.$router.push({
          path: "/report/taxNeimengSl",   //水利建设专项收入
        });
      }else if(row.tableName == 'gs_ljcl'){
          this.$router.push({
          path: "/report/taxCityLife",   //城市生活垃圾处置费
        });
      }else if(row.tableName == 'gs_xfs'){
          this.$router.push({
          path: "/report/xfs",   //城市生活垃圾处置费
        });
      }else if(row.tableName == 'gs_cbj'){
          this.$router.push({
          path: "/report/cbj",   //残疾人就业保障金申报表
        });
      }else if(row.tableName == 'gs_whsyjsfsbbygz'){
          this.$router.push({
          path: "/report/culturalTax",   //残疾人就业保障金申报表
        });
      }else if(row.tableName == 'gs_gs'){
        this.$refs.salaryDialog.init(this.comId,this.period,this.comName)
        //   this.$router.push({
        //   path: "/report/normal",   //综合所得个人所得税预扣预缴申报表
        // });
      }else if(row.tableName == 'gs_tax_dqde'){
          this.$router.push({
          path: "/report/dqde",   //个人所得税定期定额
        });
      }
    },
    handleSubject() {
      this.$refs.provision.openDialog()
    },
    handleSelectionChange(row) {
      this.sfCheck = true
      this.gsMainIds = []
      this.tableNames = []
      this.sels = row
      row.map(info=>{
        // this.gsMainIds.push({gsMainId: item.gsMainId, gsMainStatus: item.gsMainStatus})
        this.gsMainIds.push(info.id)
        this.tableNames.push(info.tableName)
      })
    },
    //申报
    shenbao() {
      if(!this.gsMainIds){
        this.$qzfMessage("请选择税表", 1)
        return
      }
      if(this.orgId == 3576 && this.automatic != 1){
        let fail = this.sels.find(v=> ![1,3,6].includes(v.djqrTaxStatus))
        if(fail){
          this.$qzfMessage('未确认，请先确认！',1)
          return
        }
      }
      let str = ''
      this.sels.map(item=>{
        if(item.status != 1){
          str = this.$tableNameFilter(item.tableName) + '暂未取数，不可申报';
          return
        }
      })
      if(str){
        this.$qzfMessage(str, 1)
        return
      }
      let gsStr = ''
      this.sels.map(item=>{
        if(item.tableName == 'gs_gs' && item.taskStatus == '3' && ['7','10','11','13','20'].includes(item.businessStatus)){
          gsStr = '个税已申报成功，不可发起，请重新选择！';
          return
        }
      })
      if(gsStr){
        this.$qzfMessage(gsStr, 1)
        return
      }
      let proceed = ''
      this.sels.map(item=>{
        if(['1','2','99'].includes(item.taskStatus)){
          proceed = this.$tableNameFilter(item.tableName) + '任务正在进行中，请重新选择！';
        }
      })
      if(proceed){
        this.$qzfMessage(proceed, 1)
        return
      }
      this.sbLoading = true
      let param = {
        taskName: 'tax_sb',
        gsMainIds: this.gsMainIds
      }
      sendTask(param).then(res => {
        this.sbLoading = false
        if(res.data.msg == 'success') {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)

        }
      })
    },
    //扣款
    batchKk(){
      if(!this.gsMainIds){
        this.$qzfMessage("请选择税表", 1)
        return
      }
      if(this.orgId == 3576 && this.automatic != 1){
        let fail = this.sels.find(v=> ![1,3].includes(v.djqrTaxStatus))
        if(fail){
          this.$qzfMessage('未确认，请先确认！',1)
          return
        }
      }
      //取数限制
      let qsStr = ''
      this.sels.map(item=>{
        if(item.status != 1){
          qsStr = this.$tableNameFilter(item.tableName) + '暂未取数，不可申报';
          return
        }
      })
      if(qsStr){
        this.$qzfMessage(qsStr, 1)
        return
      }
      let str = ''
      //判断税务报表扣款限制/个税限制
      this.sels.map(v=>{
        if(v.tableName != 'gs_gs' && v.tableName != 'gs_gs_one' && v.tableName != 'gs_gs_hd' && v.taskStatus != 3){
          str = this.$tableNameFilter(v.tableName) + '暂未申报，不可扣款'
          return
        }else if(v.tableName == 'gs_gs' && v.businessStatus == 10){
          str = this.$tableNameFilter(v.tableName) + '无需扣款，请重新选择'
          return
        }else if(v.tableName == 'gs_gs' && v.businessStatus != 7 && v.businessStatus != 20){
          str = this.$tableNameFilter(v.tableName) + '无法扣款，请重新选择'
          return
        }else if(v.tableName == 'gs_gs_one' || v.tableName == 'gs_gs_hd'){
          str = this.$tableNameFilter(v.tableName) + '暂不支持扣款，请重新选择'
          return
        }
      })
      if(str){
        this.$qzfMessage(str, 1)
        return
      }
      let proceed = ''
      this.sels.map(item=>{
        if(['1','2','99'].includes(item.taskStatus2)){
          proceed = this.$tableNameFilter(item.tableName) + '任务正在进行中，请重新选择！';
        }
      })
      if(proceed){
        this.$qzfMessage(proceed, 1)
        return
      }
      this.$confirm('确定要扣款吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.kkLoading = true
        let param = {
          taskName: 'tax_kk',
          gsMainIds: this.gsMainIds
        }
        sendTask(param).then(res => {
          this.kkLoading = false
          if(res.data.msg == 'success') {
            this.$qzfMessage('任务已发起',3)
            this.getList()
            this.$queueMessage(res.data.data.queue)
          }
        })
      })
    },
    getList() {
      this.fetchLoading = true
      taxList({}).then(res=>{
        this.fetchLoading = false
        if(res.data.data.list){
          this.list = res.data.data.list
        }else{
          this.list = []
        }      
      })
    },
    // 取数
    fetchNumber(row){
      if(this.$checkSettleStatus()) return
      let test = this.disCode?.substring(0, 2)
      let test4 = this.disCode?.substring(0, 4)
      let str = ''
      if(row.byHand == 1){
        str = '请注意，有手工修改'+this.$tableNameFilter(row.tableName) + '，重新取数后会影响手工修改的报表数据，请确认是否继续？'
      }
      if(row.tableName == 'gs_deed' && row.typeYhs == '次' && !this.addressList.includes(test) && test4 != '3700'){
        str = '检测到印花税为次报，取数后不会生成报表，请确认是否继续？'
      }
      if(row.taskStatus == '3' && row.businessStatus !='3'){
        str = this.$tableNameFilter(row.tableName) + '已申报，确认重新取数吗?'
      }
      if(str){
        this.$confirm(str, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.qsSure(row)  
        })
      }else{
         this.qsSure(row)
      }  
    },
    qsSure(row){
      this.fetchLoading = true
      taxCreate({tableName:[row.tableName]}).then(res=>{
        this.fetchLoading = false
        if(res.data.msg == "success"){
          this.getList();
          this.$qzfMessage("取数成功")
          this.$bus.emit('voucherUpdate')
        }else{
          if(res.data.msg != 'success'){
            if(res.data.msg == '请先完成计提科目配置'){
              this.settingMsg = res.data.msg
            }
            if(this.settingMsg){
              let route = '/setting/habbitSettingBook'
              this.$refs.settingDialog.getInit(this.settingMsg,route)
            }
          }
        } 
      })
    },
    // 财务报表取数
    cwbbFetchNumber(row){
      if(this.$checkSettleStatus()) return
      let str = ''
      if(row.byHand == 1){
        str = '请注意，有手工修改财务报表，重新取数后会影响手工修改的报表数据，请确认是否继续？'
      }
      if(row.taskStatus == '3' && row.businessStatus !='3'){
        str = '财务报表已申报，确认重新取数吗?'
      }
      if(str){
        this.$confirm(str, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.cwbbQsSure()  
        })
      }else{
        this.cwbbQsSure()
      }  
    },
    cwbbQsSure(){
      this.fetchLoading = true
      let param = {
        zcfz:1,
        lrb:1,
        xjllb:1
      }
      createKj(param).then(res=>{
        if(res.data.data.subjectStatus == true){
          this.$notify.error({
            title: '提示',
            message: '银行现金金额小于0',
            duration: 0,
          });
        }
        if(res.data.data.zcLessThen0 == true){
          this.$notify.error({
            title: '提示',
            message: '资产合计金额小于0',
            duration: 0,
            offset: 75,
          });
        }
        if(res.data.data.zcfzStatus == true){
          this.$notify.error({
            title: '提示',
            message: '资产负债不平衡',
            duration: 0,
            offset: 150,
          });
        }
        this.fetchLoading = false
        if(res.data.msg == "success"){
          this.getList();
          this.$qzfMessage("操作成功")
          this.$bus.emit('voucherUpdate')

        }
      })
    },
    swbbQsSure(){
      this.fetchLoading = true
      taxCreate({tableName:this.tableNames}).then(res=>{
        this.fetchLoading = false
        if(res.data.msg == "success"){
          this.getList()
          this.$bus.emit('voucherUpdate')
          this.$qzfMessage("操作成功")
        }else{
          if(res.data.msg != 'success'){
            if(res.data.msg == '请先完成计提科目配置'){
              this.settingMsg = res.data.msg
            }
            if(this.settingMsg){
              let route = '/setting/habbitSettingBook'
              this.$refs.settingDialog.getInit(this.settingMsg,route)
            }
          }
        } 
      })
    },
    generalTax(){
      if(!this.sfCheck){
        this.swbbQsSure()
      }else{
        let test = this.disCode?.substring(0, 2)
        let test4 = this.disCode?.substring(0, 4)
        let qsStr = ''
        this.sels.map(v=>{
          if(v.byHand == 1){
            qsStr = '请注意，有手工修改'+this.$tableNameFilter(v.tableName) + '，重新取数后会影响手工修改的报表数据，请确认是否继续？'
            return
          }
          if(v.tableName.match('gs_gs')){
            qsStr = '勾选的包含查账征收、核定征收、个税综合所得，请到薪酬模块添加或修改相应的工资表'
            return
          }
          if(v.tableName == 'gs_deed' && v.typeYhs == '次' && !this.addressList.includes(test) && test4 != '3700'){
            qsStr = '检测到印花税为次报，取数后不会生成报表，请确认是否继续？'
            return
          }
          if(v.tableName != 'gs_gs' && v.tableName != 'gs_gs_one'){
            if(v.taskStatus == '3' && v.businessStatus!='3'){
              qsStr = this.$tableNameFilter(v.tableName) + '已申报，请确认是否继续？'
              return
            }
          }
        })
        if(qsStr){
          this.$confirm(qsStr, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.swbbQsSure()
          })
        }else{
          this.swbbQsSure()
        }
      }
    },
    declareDetail(e) {
      this.$refs.declareDetails.init(e.comId,this.period,e.taskName)
    },
    deductionDetail(e) {
      this.$refs.declareDetails.init(e.comId,this.period,e.taskName2)
    },
    // 个税申报图展示
    open(row, index) {
      let imageAll = []
      let arr = []
      let currentIndex = index
      this.list.map((res,i)=>{
        if(res.image.match(',')){
          let imgList = []
          let imgUrls = res.image.split(',')
          imgList = imgUrls.filter(v=>{return v})
          imgList.map((item,i2)=>{
            if(item){
              if(res.tableName == 'gs_gs' && res.businessStatus == '5' && res.ycExcel){
                arr.push({
                  img:'https://file.listensoft.net' + item + "?" + Math.random(10),
                  tableName:res.tableName,
                  ycExcel:res.ycExcel
                })
              }else{
                arr.push({
                  img:'https://file.listensoft.net' + item + "?" + Math.random(10),
                  tableName:res.tableName
                })
              }
              
              if(i< index && i2>0){
                currentIndex ++
              }
            }
          })
        }else{
          if(res.image){
            if(res.tableName == 'gs_gs' && res.businessStatus == '5' && res.ycExcel){
              arr.push({
                img:'https://file.listensoft.net' + res.image + "?" + Math.random(10),
                tableName:res.tableName,
                ycExcel:res.ycExcel
              })
            }else{
              arr.push({
                img:'https://file.listensoft.net' + res.image + "?" + Math.random(10),
                tableName:res.tableName
              })
            }
          }else{
            if(i<index){
              currentIndex --
            }
          }
        }
      })
      let url = {
        name:this.comName,
        url:arr,
      }
      imageAll.push(url)
      this.$refs.sbImage.dialog(imageAll, 0,currentIndex)
    },
    open2(row,index){
      let imageAll = []
      let arr = []
      let currentIndex = index
      this.list.map((res,i)=>{
        if(res.image2.match(',')){
          let imgList = []
          let imgUrls = res.image2.split(',')
          imgList = imgUrls.filter(v=>{return v})
          imgList.map((item,i2)=>{
            if(item){
              arr.push({
                img:'https://file.listensoft.net' + item + "?" + Math.random(10),
                tableName:res.tableName
              })
              if(i< index && i2>0){
                currentIndex ++
              }
            }
          })
        }else{
          if(res.image2){
            arr.push({
              img:'https://file.listensoft.net' + res.image2 + "?" + Math.random(10),
              tableName:res.tableName
            })
          }else{
            if(i<index){
              currentIndex --
            }
          }
        }
      })
      let url = {
        name:this.comName,
        url:arr,
      }
      imageAll.push(url)
      this.$refs.sbImage.dialog(imageAll, 0,currentIndex)
    },
    // 刷新 更新申报列表
    refreshList(){
      this.btnLoading = true
      updateSbList({}).then(res => {
        this.btnLoading = false
        if(res.data.msg == "success"){
          this.getList()
        }
      })
    },
    //复制税款
    copyTax(){
      let str = ""
      let arr = []
      this.list.map(v=>{
        let amount = Number(v.tax) + Number(v.tax_fjs) + Number(v.taxOnly)
        if(amount != 0){
          arr.push(v)
        }
      })
      if(arr.length != 0){
        str = "您的企业「" + this.comName + "」" + this.period + "账期税款如下：\r"
        let totalAmount = 0
        arr.map(v=>{
          let amount = Number((Number(v.tax) + Number(v.tax_fjs) + Number(v.taxOnly)).toFixed(2))
          if(amount != 0){
            if(v.tableName == 'gs_vat' || v.tableName == 'gs_small_vat'){
              str +=  '增值税：' + v.tax 
              str += v.tax_fjs && v.tax_fjs != '0' ? '\r附加税：' + v.tax_fjs + '\r': '\r'
              totalAmount += Number(amount)
            }else{
              str +=  this.$tableNameFilter(v.tableName) + "："+ amount + "\r"
              totalAmount += Number(amount)
            }
            
          }
        })
        str += '总计：'+totalAmount.toFixed(2)+ '\r' + this.period+"账期税款如上，请确认，如有疑问请随时沟通"
      }else{
        str = "您的企业「" + this.comName + "」" + this.period + "账期暂无税款"
      }
      this.$copyComName(str);
    },
    eval1(amount1,amount2,amount3){
      let amount = Number(amount1) + Number(amount2) + Number(amount3)
      return amount.toFixed(2)
    },
    eval2(amount){
      return eval(amount)?.toFixed(2)
    },
  }
}
</script>

<style lang="scss" scoped>
.top_right_btns {
  i {
    // margin: 0 5px;
    cursor: pointer;
    color: var(--themeColor,#17a2b8);
    font-size: 18px;
    // line-height: 24px;
  }
  div{
    margin-right:5px
  }
}
.top_btns {
  position: absolute;
  display: inline;
  top: 8px;
  right: 16px;
  z-index: 999;
}
.item_icon{
  display: inline-block;
  cursor: pointer;
  i{
    display: inline-block;
    font-size: 13px;
    margin-right:4px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-info{
    color: #409eff
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }

  p{
    display: inline-block;
    font-size: 13px;
    //color: #333;
  }
}
.hint {
  font-size: 13px;
  color: red;
  margin-right: 10px;
  .icon-gantanhao1 {
    font-size: 13px;
    margin-right: 3px;
  }
}

</style>